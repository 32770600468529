.player-card {
	background-color: rgba(23, 25, 31, 0.5);
	margin-bottom: 2em;
	color: #fdfdfd;
  }
  
  .button-card {
	transition: all 250ms cubic-bezier(.27,.01,.38,1.06);
	background-color: rgba(23, 25, 31, 0.5);
	color: #fdfdfd;
	padding: 2em;
	display: inline-block;
	cursor: pointer;
  }
  
  .button-card:hover {
	background-color: rgba(23, 25, 31, 0.9);
	color: #fdfdfd;
  }
  
  .button-card > *:first-child {
	margin-right: 1em;
  }
  
  .player-container {
	  color: #fdfdfd !important;
	  padding-left: 4em;
	  padding-top: 4em;
	  padding-right: 4em;
  }
  
  .player-info-container {
	  padding-top: 2em;
	  padding-left: 3em;
	  padding-right: 3em;
	  padding-bottom: 2em;
  }
  
  .player-avatar-body {
  
  }
  
  .rank-plate {
	  padding-left: 0.5em;
	  padding-right: 0.5em;
	  width: fit-content;
	  border-radius: 5px;
	  font-weight: 600;
  }
  
  .rank-plate > span {
	  
  }
  
  .skin {
	  perspective: 1000px;
	  perspective-origin: 50% -100%;
	  height: 125px;
	  width: 125px;
	  position: relative;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	}
	
	.innerCube {
	  width: 85px;
	  height: 85px;
	  position: absolute;
	  transform-style: preserve-3d;
	  animation: spin 5s linear infinite;
	}
	
	.innerCube .front, .innerCube .back, .innerCube .right, .innerCube .left, .innerCube .top, .innerCube .bottom {
	  width: 80px;
	  height: 80px;
	  background-size: 1280px 1280px;
	  top: 5px;
	  left: 5px;
	}
	
	.innerCube .hatFront, .innerCube .hatBack, .innerCube .hatRight, .innerCube .hatLeft, .innerCube .hatTop, .innerCube .hatBottom {
	  width: 85px;
	  height: 85px;
	  background-size: 1360px 1360px;
	}
	
	.innerCube .front, .innerCube .back, .innerCube .right, .innerCube .left, .innerCube .top, .innerCube .bottom,
	.innerCube .hatFront, .innerCube .hatBack, .innerCube .hatRight, .innerCube .hatLeft, .innerCube .hatTop, .innerCube .hatBottom {
	  position: absolute;
	  background-repeat: no-repeat;
	  image-rendering: -moz-crisp-edges;
	  image-rendering: -o-crisp-edges;
	  image-rendering: -webkit-optimize-contrast;
	  -ms-interpolation-mode: nearest-neighbor;
	  image-rendering: pixelated;
	}
	
	.innerCube .front  { transform: rotateY(   0deg ) translateZ( 40px ); background-position: -160px -160px; }
	.innerCube .back   { transform: rotateY( 180deg ) translateZ( 40px ); background-position: -480px -160px; }
	.innerCube .right  { transform: rotateY(  90deg ) translateZ( 40px ); background-position: -320px -160px; }
	.innerCube .left   { transform: rotateY( -90deg ) translateZ( 40px ); background-position: 0px -160px; }
	.innerCube .top    { transform: rotateX(  90deg ) translateZ( 40px ); background-position: -160px 0px; }
	.innerCube .bottom { transform: rotateX( -90deg ) translateZ( 40px ); background-position: -320px 0px; }
	
	.innerCube .hatFront  { transform: rotateY(   0deg ) translateZ( 42.5px ); background-position: -850px -170px; }
	.innerCube .hatBack   { transform: rotateY( 180deg ) translateZ( 42.5px ); background-position: -1190px -170px; }
	.innerCube .hatRight  { transform: rotateY(  90deg ) translateZ( 42.5px ); background-position: -1020px -170px; }
	.innerCube .hatLeft   { transform: rotateY( -90deg ) translateZ( 42.5px ); background-position: -680px -170px; }
	.innerCube .hatTop    { transform: rotateX(  90deg ) translateZ( 42.5px ); background-position: -850px 0px; }
	.innerCube .hatBottom { transform: rotateX( -90deg ) translateZ( 42.5px ); background-position: -1020px 0px; }
	
	
	@keyframes spin {
	  from { transform: rotateY(0deg); }
	  to { transform: rotateY(360deg); }
	}
  
  .character-origin-card {
	  border-radius: .625rem;
	  padding: 1em;
  }
  
  .character-info-card {
	  border-radius: .625rem;
	  padding: 1em;
  }
  
  .universe-card {
	cursor: pointer;
	transition: all .2s ease-in-out;
  }
  
  .universe-card:hover {
	transform: scale(1.025);
  }
  
  @media only screen and (max-width: 768px) {
	  .player-container {
	  color: #fdfdfd !important;
	  padding-left: 2em;
	  padding-top: 2em;
	  padding-right: 2em;
	}
  }

.back-button {
	transition: all 250ms cubic-bezier(.27,.01,.38,1.06);
	cursor: pointer;
	font-size: 2em;
}

.back-button:hover {
	color: rgba(136, 136, 136, 0.9);
}

.back-button-container {
	position: absolute;
	left: 1em; 
	height: 100%; 
	display: flex; 
	flex-direction: column; 
	justify-content: center;
	z-index: 1;
}