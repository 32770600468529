.community-standing-badge {
    border-radius: 0.5em;
    padding-left: 0.675em;
    padding-right: 0.675em;
}

.community-standing-badge.very-good {
    background-color: #00AA00;
    color: #fdfdfd;
}

.community-standing-badge.good {
    background-color: #FFEF00;
    color: black;
}

.community-standing-badge.moderate {
    background-color: #FFAA00;
    color: black;
}

.community-standing-badge.bad {
    background-color: #FF5555;
    color: black;
}

.community-standing-badge.very-bad {
    background-color: #AA0000;
    color: #fdfdfd;
}

h3.skeleton-line {
    height: 1em;
    margin-bottom: .75rem;
}

.skeleton-line {
    background-color: rgba(1, 1, 1, 0.25);
    height: 1.5em;
    width: 100%;
    background-image: linear-gradient(to right, #bdbdbd 0%, #999999 20%, #bdbdbd 40%, #bdbdbd 100%);
    background-size: 1000px 400px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
}

.skeleton-avatar {
    background-color: rgba(1, 1, 1, 0.25);
    height: 80px;
    width: 80px;
    background-image: linear-gradient(to right, #bdbdbd 0%, #999999 20%, #bdbdbd 40%, #bdbdbd 100%);
    background-size: 1000px 400px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
}

@keyframes shimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
}