.white {
	color: #fdfdfd !important;
}

.app {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: cover;
}

.logincardbody {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.logincardimage {
	height: 25vh;
	width: 25vh;
	margin-left: 2em;
	margin-right: 2em;
}
