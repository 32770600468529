.player-search {
    margin-bottom: 1em;
}

h5.skeleton-card-line {
    height: 1.5em;
    margin-bottom: .75rem;
}

.skeleton-card-line {
    background-color: rgba(1, 1, 1, 0.25);
    height: 1.5em;
    width: 100%;
    background-image: linear-gradient(to right, #bdbdbd 0%, #999999 20%, #bdbdbd 40%, #bdbdbd 100%);
    background-size: 800px 400px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
}

.skeleton-card-avatar {
    max-width: 100%;
    max-height: 100%;
    height: 80px; 
    width: 80px;
    background-color: rgba(1, 1, 1, 0.25);
    margin-bottom: 0.5em;
    background-image: linear-gradient(to right, #bdbdbd 0%, #999999 20%, #bdbdbd 40%, #bdbdbd 100%);
    background-size: 800px 400px;
}

@keyframes shimmer {
    0% {
      background-position: -468px 0
    }
    100% {
      background-position: 468px 0
    }
}