body {
	overflow-x: hidden;
}


/* * {
	cursor: url("https://tarvos-gaming.com/media/tarvos-cursor-standard.png"), auto; 
}
*/
/*
button,a,select {
  cursor: url("https://tarvos-gaming.com/media/tarvos-cursor-hover.png"), auto !important; 
}
*/
body:after {
	content: "";
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
}

.main-container {
	width: 100%;
	height: 100%;
}

div.noPadding {
    padding-left: 0;
    padding-right: 0;
}
/*
.noPadding .container-fluid {
	padding-left: 0 !important;
}
*/
div.noMargin {
    margin-left: 0;
    margin-right: 0;
}
.title {
	margin-top: 30px;
	margin-bottom: 1em;
	display: inline-block;
}

.title-block {
	margin-top: 30px;
	margin-bottom: 1em;
}

.title-button {
	margin-top: -15px;
	margin-left: 15px;
}

div.center {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.reportTableAction {
	margin-right: 1em;
}

.reportIndicator {
	margin-right: 1em;
}

.reportIndicatorGroup {
	margin-bottom: 1em;
}

.logTopActionGroup {
	display: flex;
}

.spacer {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

h1, h2, h3, h4, h5, h6, p {
	color: inherit;
}