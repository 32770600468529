.player-avatar-circle {
    border-radius: 50%;
    border-color: #fdfdfd;
    border-style: solid;
    cursor: pointer;
    transition: all .2s ease-in-out;
    width: 160px;
    height: 160px;
    margin-top: 4vh;
    margin-bottom: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
}

.player-avatar-circle:hover {
    transform: scale(1.1);
}

.player-picker-container {
    color: #fdfdfd !important;
    align-self: center;
}

.player-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
}