.clan-card {
	background-color: rgba(23, 25, 31, 0.5);
	margin-bottom: 2em;
	color: #fdfdfd;
    padding-left: 4em;
    padding-top: 4em;
    padding-right: 4em;
	padding-bottom: 4em;
}

.member-card {
	transition: all 250ms cubic-bezier(.27,.01,.38,1.06);
	background-color: rgba(23, 25, 31, 0.5);
	color: #fdfdfd;
	padding: 2em;
	display: inline-block;
	cursor: pointer;
}

.member-card:hover {
	background-color: rgba(23, 25, 31, 0.9);
	color: #fdfdfd;
}

.member-card > *:first-child {
	margin-right: 1em;
}