.punish-option-btn {
    cursor: pointer;
    transition: all 250ms cubic-bezier(.27,.01,.38,1.06);
    height: 100%;
}

.punish-option-btn div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.punish-option-btn:hover {
    background-color: #007bff;
    color: #fdfdfd;
}