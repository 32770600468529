.logviewer {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    border-radius: 1em;
    background-color: #17191f;
    color: #fdfdfd;
    margin-left: 0 !important;
    margin-top: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    height: 60vh;
    overflow-y: scroll;
}

.logline {
    cursor: pointer;
    text-decoration: none;
    color: #fdfdfd;
    overflow-wrap: anywhere;
}

.logline:hover {
    background-color: #2b2f3b;
}