.navbarimg {
    width: 4em;
}

.mobilenav {
    padding-top: 0.675em;
    padding-bottom: 0.675em;
    color: #fdfdfd;
    background-color: #17191f;
}

.mobilenav a {
    color: #fdfdfd;
}

.mobilenav a:hover {
    color: #c2c9d6;
}

.mobilenav:hover {
    background-color: #17191f;
}

.themeToggle {
    cursor: pointer;
    color: #fdfdfd;
    transition: color 0.2s ease;
}

.themeToggle:hover {
    color: #007bff;
}

.vertical-divider {
    border-left: 1px solid #fdfdfd;
    height: auto;
    margin-left: 0.675em;
    margin-right: 0.675em;
}